.text-container {
  overflow: hidden;
  padding: 100px;
  color: black;
  text-align: left;
}

.headshot{
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  border-radius: 250px;
}

h1 {
  margin-bottom: 0;
  font-size: 3em;
  font-family: 'Source Sans Pro', sans-serif;
}


.information {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 5px; /* Adjust spacing between items */
}

.information img {
  max-width: 100%; 
}

.information p {
  flex: 1; /* Allow the text to take up the remaining space */
}

@media screen and (max-width: 768px) {
  .information {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
}