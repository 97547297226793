.externalLinks{
    padding: 10px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.icon1 {
    display:inline-block;
}

.icon2 {
    display: inline-block;
}

.icon3 {
    display: inline-block;
}

.linkedin {
    width: 100px;
    height: 100px; 
}

.resume {
    width: 100px;
    height: 100px; 
}

.github {
    width: 100px;
    height: 100px; 
}

.icon1:hover .linkedin {
    transform: rotate(20deg);
  }

.icon2:hover .github {
    transform: rotate(20deg);
}

.icon3:hover .resume {
    transform: rotate(20deg);
}