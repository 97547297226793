/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    transform: translate3d(-index * 100, 0, 0);
    
}

.slide {
    display: inline-block;

    height: 400px;
    width: 100%;
    border-radius: 40px;
}

/* Buttons */
.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: black;
  }

.showcaseTitle{
    text-align:center;
    text-decoration: underline;
}